<template>
  <base-card header="bg-info" body="bg-light shadow">
    <template #header>إنشاء مزاد</template>
    <template #body>
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <h6 class="link">الصور</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-sm-6">
            <form-image
                ref="main_photo"
                id="main_photo"
                @uploaded="uploaded"
                @cleared="imagesCleared"
                :show-clear="!uploading"
                :show-count="!uploading"
                :disabled-icon="disabledIcon"
            >
              <template #default>
                <p class="pm-0">الرئيسية</p>
                <small class="text-success font-weight-bolder">(مطلوب)</small>
              </template>
            </form-image>
          </div>
          <div class="col-md-3 col-sm-6">
            <form-image
                ref="auction_photos"
                id="auction_photos"
                name="auction_photos[]"
                :multiple="true"
                @uploaded="uploaded"
                @cleared="imagesCleared"
                :show-clear="!uploading"
                :show-count="!uploading"
                :disabled-icon="disabledIcon"
            >
              <template #default>
                <p class="pm-0">صور المزاد</p>
                <small class="text-primary font-weight-bolder">(إختياري)</small>
              </template>
            </form-image>
          </div>
          <div class="col-md-3 col-sm-6">
            <form-image
                ref="receipt_form"
                id="receipt_form"
                @uploaded="uploaded"
                @cleared="imagesCleared"
                :show-clear="!uploading"
                :show-count="!uploading"
                :disabled-icon="disabledIcon"
            >
              <template #default>
                <p class="pm-0">نموذج الإستلام</p>
                <small class="text-success font-weight-bolder">(مطلوب)</small>
              </template>
            </form-image>
          </div>
          <div class="col-md-3 col-sm-6">
            <form-image
                ref="auth_form"
                id="auth_form"
                @uploaded="uploaded"
                @cleared="imagesCleared"
                :show-clear="!uploading"
                :show-count="!uploading"
                :disabled-icon="disabledIcon"
            >
              <template #default>
                <p class="pm-0">نموذج التفويض</p>
                <small class="text-success font-weight-bolder">(مطلوب)</small>
              </template>
            </form-image>
          </div>
        </div>
        <hr/>
      </div>
      <form @submit.prevent="store" id="create-auction">
        <div class="container">
          <div class="row pt-0 mt-0">
            <div class="col-sm-12">
              <div class="d-flex justify-content-end mb-2">
                <button
                    class="btn btn-sm btn-success nr-shadow"
                    v-if="!uploading"
                >
                  <span class="mx-1">إنشاء</span>
                  <i class="fa fa-check mx-1"></i>
                </button>
                <button
                    type="button"
                    class="btn btn-sm btn-success nr-shadow"
                    disabled
                    v-else
                >
                  <span class="mx-1">
                    جارِ تحميل صور المزاد
                  </span>
                  <i class="fas fa-spinner fa-pulse mx-1"></i>
                </button>
              </div>
              <AuctionData/>
            </div>
          </div>
          <hr/>
        </div>
        <div class="container">
          <h6 class="text-left link">الملاحظات</h6>
          <div class="row">
            <div class="col-sm-12">
              <AuctionNotes ref="notes"/>
            </div>
          </div>
        </div>
      </form>
    </template>
  </base-card>
</template>

<script>
import BaseCard from "@/components/UI/BaseCard";
import AuctionData from "@/components/Auctions/AuctionData";
import FormImage from "@/components/Forms/FormImage";
import AuctionNotes from "@/components/Auctions/AuctionNotes";
import cars from "@/mixins/cars";
import validation from "@/validation/validation";
import Storage from "@/common/Storage";
import axios from "axios";
import types from "@/common/types";

export default {
  components: {AuctionNotes, FormImage, AuctionData, BaseCard},
  mixins: [cars],
  data() {
    return {
      uploading: false,
      uploadedImages: {},
      needUpload: {},
    };
  },
  computed: {
    shouldReload() {
      return this.$store.getters.shouldReload;
    },
    authorized() {
      return this.$store.getters.authorized;
    },
    imagesRoute() {
      return `${this.$store.getters.publicPath}/api/auctions/store`;
    },
    disabledIcon() {
      return this.uploading ? "fas fa-spinner fa-pulse" : "fa fa-upload";
    },
  },
  methods: {
    async store(e) {
      validation.clearErrors();

      const valid = validation.validate(e.target.id, ["manufacturer", "model"]);
      if (valid.status && !!valid.data) {
        validation.clearErrors();
        const data = valid.data;

        if (types.isObject(this.uploadedImages)) {
          data.set("images", JSON.stringify(this.uploadedImages));
        }

        const response = await http.send("auctions", data);
        if (http.responseAccepted(response)) {
          Storage.removeStartWith("auction-categories");

          if (response.data.auction) {
            Storage.set("created_auction", response.data.auction, 1);
            await this.$router.replace({name: "auctions.created"});
            this.uploadedImages = {};
          }
        }
      } else {
        http.popupMessage("error", messages.checkFields);
      }
    },
    async uploaded(e) {
      const images = {
        main_photo: this.needUpload["main_photo"] || [],
        auction_photos: this.needUpload["auction_photos"] || [],
        receipt_form: this.needUpload["receipt_form"] || [],
        auth_form: this.needUpload["auth_form"] || [],
      };

      const files = !!e && !!e.target ? e.target.files : "";
      const id = e.target.id;

      if (!files || !this.authorized || !id || !images[id]) {
        return;
      }

      for (const image of files) {
        images[id].push(image);
      }

      this.needUpload = images;
      if (!this.uploading) {
        await this.startUpload();
      }
    },
    async startUpload() {
      this.uploading = true;
      const images = {
        main_photo: this.uploadedImages["main_photo"] || [],
        auction_photos: this.uploadedImages["auction_photos"] || [],
        receipt_form: this.uploadedImages["receipt_form"] || [],
        auth_form: this.uploadedImages["auth_form"] || [],
      };

      const flags = {
        main_photo: 1,
        auction_photos: 2,
        receipt_form: 3,
        auth_form: 4,
      };

      for (const imageType in this.needUpload) {
        for (const image of this.needUpload[imageType]) {
          const formData = new FormData();
          formData.append("image", image);
          formData.set("flag", flags[imageType]);

          try {
            const config = {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${this.authorized.token}`
              }
            };

            const response = await axios.post(`${this.imagesRoute}/images-upload`, formData, config);
            if (http.responseAccepted(response, 'path')) {
              images[imageType].push({
                path: response.data.path,
                flag: response.data.flag,
              });
            }

            this.needUpload[imageType] = this.filterImages(
                this.needUpload[imageType],
                image
            );

          } catch (e) {
            const response = e.response
            if (!!response && [422, 413].includes(response.status)) {
              const data = response.data
              const msg = !!data ? data.msg : "";
              if (!!msg) {
                http.popupMessage("error", msg);
              }

              await this.$refs[imageType].clearInputFile(imageType);

              const imagesNames = {
                main_photo: "الصورة الرئيسية",
                auction_photos: "صور المزاد",
                receipt_form: "صورة نموذج الإستلام",
                auth_form: "صورة نموذج التفويض",
              };

              if (!msg) {
                http.popupMessage("error", `${imagesNames[imageType]} كبيرة للغاية`);
              }
            }
          }
        }
      }
      this.uploadedImages = images;
      this.checkUploading();
    },
    async imagesCleared(id) {
      const deletedUploadedImages = this.uploadedImages[id];
      delete this.needUpload[id];
      delete this.uploadedImages[id];
      if (!!deletedUploadedImages && deletedUploadedImages.length > 0)
        await axios.post(
            `${this.imagesRoute}/delete-images`,
            {paths: deletedUploadedImages},
            {
              headers: {Authorization: `Bearer ${this.authorized.token}`},
            }
        );
      this.checkUploading();
    },
    filterImages(array, file) {
      return !array || !file
          ? array
          : array.filter((filtered) => filtered !== file);
    },
    checkUploading() {
      this.uploading =
          !!this.needUpload &&
          this.needUpload.length > 0 &&
          Object.entries(this.needUpload).reduce(
              (images) => !!images && images.length > 0
          );
    },
  },
};
</script>
