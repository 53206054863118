<template>
  <div class="w-100 text-left">
    <button type="button" class="btn btn-info nr-shadow" @click="addNote">
      <span>إضافة ملاحظة</span>&nbsp;<span class="fa fa-plus"></span>
    </button>
  </div>
  <br>
  <div class="row" v-for="(note, index) in createdNotes" :key="note.id">
    <div class="col-md-2">
      <span>ملاحظة</span>&nbsp;<span>{{ index + 1 }}</span>
      &nbsp;
      <sub class="link blue-link pointer font-weight-bolder" @click="removeNote(note.id)">إزالة</sub>
    </div>
    <div class="col-md-10">
      <bootstrap-select v-if="!note.newNote" :id="note.id" :options="notes"
                        @changed="setNewNote($event, note)">
        <template #default>
          <option value="new">أخرى</option>
        </template>
      </bootstrap-select>
      <div v-else>
        <textarea :id="note.id" :name="note.id" placeholder="اكتب ملاحظاتك .." rows="5" class="form-control"></textarea>
        <button type="button" class="link blue-link" @click="note.newNote = false">العودة للقائمة</button>
      </div>
    </div>
  </div>
</template>

<script>
import BootstrapSelect from "@/components/Forms/BootstrapSelect";
import Storage from "@/common/Storage";

export default {
  components: {BootstrapSelect},
  data() {
    return {
      createdNotes: [],
    };
  },
  computed: {
    notes() {
      return this.$store.getters.notes;
    }
  },
  methods: {
    addNote() {
      this.createdNotes.push({id: `note${this.createdNotes.length + 1}`, newNote: false});
    },
    removeNote(id) {
      this.createdNotes = this.createdNotes.filter(note => {
        return note.id !== id;
      });
    },
    setNewNote(value, note) {
      note.newNote = value === "new";
    },
  },
  async created() {
    await this.$store.dispatch('setNotesAction');
  }
}
</script>
